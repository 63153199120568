@import 'variables';

$prefix: $antPrefix + -descriptions;

.#{$prefix} {
  &-bordered {
    .#{$prefix} {
      &-view,
      &-row,
      &-item-label,
      &-item-content {
        border-color: $contrastBackground;
      }
      &-item-label {
        background: $contrastBackground;
      }
    }
  }
  &-title,
  &-item-label,
  &-item-content {
    color: $textColor;
  }

  .#{$prefix}-view {
    .#{$prefix}-row {
      .#{$prefix}-item {
        .#{$prefix}-item-container {
          flex-direction: column;
          .#{$prefix}-item-label {
            color: $textColorSecondary;
          }
          .#{$prefix}-item-content {
            .ant-typography {
              font-weight: 400 !important;
            }
          }
        }
      }
    }
  }
}
