@import 'variables';

.gantt {
    .ant-space-item:nth-child(2) {
        .ant-col {
            .gantt_layout_cell_border_bottom {
                border-color: transparent;
                .gantt_layout_cell_border_right {
                    >.gantt_layout_content {
                        >.gantt_grid {
                            >.gantt_grid_data {
                                border-bottom: 1px solid $borderColor; 
                                border-left: 1px solid $borderColor; 
                                .gantt_row_task {
                                    border-bottom: 1px solid $borderColor;
                                }
                                .gantt_row_task:hover {
                                    background: $itemHoverBackground;
                                }
                            }
                        }
                    }
                }
                .gantt_layout_outer_scroll_horizontal {
                    .gantt_layout_content {
                        .gantt_task {
                            border-bottom: 1px solid $borderColor;
                            border-right: 1px solid $borderColor;
                            .gantt_task_scale {
                                .gantt_scale_line:nth-child(1) {
                                    .gantt_scale_cell {
                                        background-color: $textColor;
                                        color: $clientColor;
                                    }
                                }
                                .gantt_scale_line:nth-child(2) {
                                    .gantt_scale_cell {
                                        border-color: transparent;
                                        color: $textColor;
                                    }
                                }
                            }
                            .gantt_data_area {
                                .gantt_task_bg {
                                    .gantt_task_row {
                                        border-bottom: 1px solid $borderColor;
                                        .gantt_task_cell {
                                            border: transparent;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
