@import 'variables';

.drawer-action-row {
  padding: 16px 0 8px;

  > .#{$antPrefix}-col:first-child,
  > .#{$antPrefix}-row > .#{$antPrefix}-col:first-child {
    padding-right: 4px;
  }

  > .#{$antPrefix}-col:last-child,
  > .#{$antPrefix}-row > .#{$antPrefix}-col:last-child {
    padding-left: 4px;
  }

  .#{$antPrefix}-divider {
    color: $primaryColor;
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 8px;

    &::before,
    &::after {
      border-color: $borderColor;
    }
  }
}

.input-tag-wrapper {
  border: 1px solid $borderColor;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 8px;

  .#{$antPrefix}-divider {
    color: $primaryColor;
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 8px;

    &::before,
    &::after {
      border-color: $borderColor;
    }
  }
}

.#{$antPrefix}-tag.not-draggable {
  margin: 2px;
  white-space: normal;
}

.#{$antPrefix}-tag.draggable {
  cursor: grab;
  opacity: 0.9;
  transition: all 0.2s linear;
  margin: 2px 4px;

  &:hover {
    opacity: 1;
    transform: scale(1.05);
  }

  &:active {
    cursor: grabbing !important;
    opacity: 1;
  }
}

.#{$antPrefix}-mentions.input-code {
  textarea {
    background: #202124;
    color: #bdc1c6;
    font-family: 'Ubuntu', sans-serif;

    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
    }
  }

  &:hover,
  &:focus {
    border-color: $addColor;
  }
}

.#{$antPrefix}-input.input-code {
  background: #202124;
  color: #bdc1c6;
  font-family: 'Ubuntu', sans-serif;

  &:hover,
  &:focus {
    border-color: $addColor;
  }
}

.#{$antPrefix}-mentions-focused.input-code {
  box-shadow: 0 0 0 2px $addColor;
}
