@import 'variables';

$prefix: $antPrefix + -typography;

.#{$prefix},
h1.#{$prefix},
h2.#{$prefix},
h3.#{$prefix},
h4.#{$prefix},
h5.#{$prefix},
h6.#{$prefix} {
    color: $textColor;
}

.#{$prefix}.login-title {
    margin-top: 60px;
    margin-bottom: 40px;
    font-size: 30px;
}

h2 {
    font-size: 16px;
    margin-left: 60px;
}