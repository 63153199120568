@import 'variables';

$prefix: $antPrefix + -page-header;

.#{$prefix} {
  color: $textColor;
  background: $componentBackground;
  border-radius: 0%;
  border-bottom: 1px solid $contrastBackground;
  padding: 36px 24px 10px 24px;

  &-heading-title,
  &-back-button,
  & > .#{$antPrefix}-breadcrumb a:not(:hover),
  & > .#{$antPrefix}-breadcrumb > span:last-child a {
    color: $textColor;
  }
  & > .#{$antPrefix}-breadcrumb > span:last-child a {
    cursor: auto;
  }

  .#{$antPrefix}-breadcrumb-separator {
    color: $textColorSecondary;
  }
}
