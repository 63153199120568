@import 'variables';

$prefix: $antPrefix + -list;

.#{$prefix} {
  color: $textColor;
  &-item-meta {
    &-title,
    &-description {
      color: $textColor;
    }
  }
  &-item {
    border-color: $borderColor !important;
  }
}
.custom-document-list {
  .custom-list-item {
    justify-content: space-between;
    padding: 8px 8px;
    margin: 0 16px 12px 0;
    height: 40px;
    border-bottom: 1px solid $disabledColor;
    .document-meta {
      justify-content: space-between;
      margin-left: 8px;
      align-items: center;
      display: flex;
      .ant-row {
        width: 100%;
        .custom-list-label {
          font-size: 13px;
          .anticon-close-circle {
            color: $errorColor;
            padding-right: 8px;
            svg {
              width: 17px;
              height: 17px;
            }
          }
          .anticon-check-circle {
            color: $addColor;
            padding-right: 8px;
            svg {
              width: 17px;
              height: 17px;
            }
          }
        }
        .customfile {
          padding: 1px;
          display: flex;
          justify-content: right;
        }
      }
    }
    .custom-list-action {
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: right;
      .ant-btn-link {
        padding: 0 8px;
        color: $primaryColor;
        display: flex;
        height: fit-content;
      }
      .anticon-delete {
        padding: 0 8px;
        color: $errorColor;
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}

