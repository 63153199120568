@import 'variables';

.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
}

.reactflow-wrapper {
  flex-grow: 1;
  height: 650px;
}

aside:not(.#{$antPrefix}-layout-sider) {
  border-right: 1px solid $borderColor;
  padding: 15px 10px;
  font-size: 12px;
  background: $componentBackground;
}

.dndnode {
  width: 170px;
  max-width: 100%;
  padding: 10px;
  font-size: 12px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  margin-bottom: 20px;
  text-align: center;
}

.isNotConform {
  color: $errorColor;
}

.dndnode.start {
  border-color: #0041d0;
  background: #b7cbf6;
  width: 60px;
  height: 60px;
  border-radius: 40px;
}

.dndnode.decision {
  border-color: #49d000;
  background: #cdf3b8;
  width: 60px;
  height: 60px;
}

.dndnode.subprocess {
  border: 3px double #1a192b;
}

.dndnode.end {
  border-color: #ff0072;
  background: #f8aacd;
  width: 60px;
  height: 60px;
  border-radius: 40px;
}

.dndnode.log {
  border-color: #b1b1b7;
  background: #fcfcfc;
  width: 60px;
  height: 60px;
  border-radius: 20px;
}

.react-flow__controls-button {
  height: 30px;
  width: 30px;
}

.react-flow__handle {
  height: 10px;
  width: 10px;
  min-height: 10px;
  min-width: 10px;
  max-height: 10px;
  max-width: 10px;
  border-radius: 100%;
}

.react-flow__node {
  width: 170px;
  font-size: 10px;
  background: #fcfcfc;
  padding: 10px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  margin-bottom: 10px;
}

.react-flow__node-input {
  border-color: #0041d0;
  background: #b7cbf6;
  text-align: center;
  width: 70px;
  height: 70px;
  border-radius: 40px;
}

.react-flow__node-decision {
  border-color: #49d000;
  background: #cdf3b8;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
}

.react-flow__node-subprocess {
  width: 170px;
  background: $componentBackground;
  padding: 10px;
  border: 3px double #1a192b;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  margin-bottom: 10px;
}

.react-flow__node-output {
  border-color: #ff0072;
  background: #f8aacd;
  text-align: center;
  width: 70px;
  height: 70px;
  border-radius: 40px;
}

.react-flow__node-log {
  border-color: #b1b1b7;
  background: #fcfcfc;
  text-align: center;
  width: 70px;
  height: 70px;
  border-radius: 20px;
}

.edgebutton {
  background: #eee;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  height: 20px;
  line-height: 1;
  width: 20px;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.edgebutton-foreignobject body {
  align-items: center;
  background: transparent;
  display: flex;
  height: 40px;
  justify-content: center;
  min-height: 40px;
  width: 40px;
}

.react-flow__edge {
  pointer-events: visibleStroke;
}

.react-flow__edge-path {
  stroke: #b1b1b7;
  stroke-width: 2;
  fill: none;
}

foreignObject {
  display: block;
  overflow: hidden;
}

.edgebutton {
  background: #eee;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  height: 20px;
  line-height: 1;
  width: 20px;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.edgebutton-foreignobject body {
  align-items: center;
  background: transparent;
  display: flex;
  height: 40px;
  justify-content: center;
  min-height: 40px;
  width: 40px;
}

.floatingedges {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.floatingedges .react-flow__handle {
  opacity: 0;
}

.layoutflow {
  flex-grow: 1;
  position: relative;
}

.layoutflow .controls {
  font-size: 12px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}

.layoutflow .controls button:first-child {
  margin-right: 10px;
}

.providerflow {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.providerflow aside {
  background: #fff;
  border-left: 1px solid #eee;
  font-size: 12px;
  padding: 15px 10px;
}

.providerflow aside .description {
  margin-bottom: 10px;
}

.providerflow aside .title {
  font-weight: 700;
  margin-bottom: 5px;
}

.providerflow aside .transform {
  margin-bottom: 20px;
}

.providerflow .reactflow-wrapper {
  flex-grow: 1;
}

.providerflow .selectall {
  margin-top: 10px;
}

.save__controls {
  font-size: 12px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 4;
}

.save__controls button {
  margin-left: 5px;
}

.transition {
  flex-grow: 1;
  position: relative;
}

.transition .controls {
  font-size: 12px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}

.transition .controls button + button {
  margin-left: 10px;
}

.updatenode__controls {
  font-size: 12px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 4;
}

.updatenode__controls label {
  display: block;
}

.updatenode__bglabel {
  margin-top: 10px;
}

.updatenode__checkboxwrapper {
  align-items: center;
  display: flex;
  margin-top: 10px;
}

.validationflow .react-flow__node {
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #555;
  font-size: 12px;
  padding: 10px;
  text-align: center;
  width: 150px;
}

.validationflow .react-flow__node-customnode {
  background: #e6e6e9;
  border: 1px solid #ddd;
}

.react-flow__node-custominput .react-flow__handle {
  background: #e6e6e9;
}

.validationflow .react-flow__node-custominput {
  background: #fff;
}

.validationflow .react-flow__handle-connecting {
  background: #ff6060;
}

.validationflow .react-flow__handle-valid {
  background: #5d9;
}

.zoompanflow {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.zoompanflow aside {
  background: #fff;
  border-left: 1px solid #eee;
  font-size: 12px;
  padding: 15px 10px;
}

.zoompanflow aside .description {
  margin-bottom: 10px;
}

.zoompanflow aside button {
  display: block;
  margin-bottom: 5px;
}

.zoompanflow .reactflow-wrapper {
  flex-grow: 1;
}

@media screen and (min-width: 768px) {
  .zoompanflow {
    flex-direction: row;
  }

  .zoompanflow aside {
    max-width: 250px;
    width: 20%;
  }
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }
}

@media screen and (min-width: 768px) {
  .providerflow {
    flex-direction: row;
  }

  .providerflow aside {
    max-width: 250px;
    width: 20%;
  }
}
