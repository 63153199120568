@import 'variables';

$prefix: $antPrefix + -upload;

.#{$prefix}, .#{$antPrefix}-form-item  {
  color: $textColor;

  .#{$prefix}.#{$prefix}-drag {
    background: $componentBackground;
  }
}

.#{$prefix}-drag {
  background: transparent;
  border: 1px dashed $primaryColor;
  p.#{$prefix}-drag-icon {
    .anticon {
      font-size: 45px;
      color: $textColor;
    }
  }
  p.#{$prefix}-text {
    color: $textColorSecondary;
  }
}