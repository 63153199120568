@import 'variables';

$prefix: $antPrefix + -form;

.quotation-drawer-button {
    position: fixed;
    top: 65%;
    right: 20px;
    transform: translateY(-50%);
    background-color: $clientColor;
    .quotation-drawer {
        border: 1px solid $primaryColor;
        border-radius: 14px;
    }
}