@import 'variables';

$prefix: $antPrefix + -card;

.#{$prefix} {
  background: $componentBackground;
  color: $textColor;

  &-head,
  &-meta-title,
  &-meta-description {
    color: $textColor;
  }
  &-head {
    border-bottom: none;
    .#{$prefix}-head-wrapper {
      display: block;
      .#{$prefix}-head-title {
        font-weight: 600;
        padding: 16px 0 8px 0;
        border-bottom: 2px solid $primaryColor;
        display: inline-block;
      }
      .#{$prefix}-extra {
        position: absolute;
        top: 0px;
        right: 14px;
      }
    }
  }

  &-body {
    padding: 24px 24px 24px 36px;
  }
  &-bordered {
    border: 1px solid rgba(199, 199, 206, 1);
    border-radius: 4px;
  }
}



