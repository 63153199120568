@import 'variables';

$prefix: $antPrefix + -collapse;

.#{$prefix} {
  color: $textColor !important;
  line-height: 35px;
  &-borderless {
    background-color: transparent !important;
    & > .#{$prefix}-item > .#{$prefix}-content {
      background: transparent !important;
    }
  }

  & > .#{$prefix}-item > .#{$prefix}-header {
    color: $textColor !important;
  }

  &-content {
    color: $textColor !important;
    background: transparent !important;
  }
}

.#{$prefix}-content-box .ant-collapse-content-box {
  padding: 0 !important;
}

.#{$prefix}-icon-position-start {
  border: none;
  background-color: transparent;
  .#{$prefix}-item {
    .#{$prefix}-header {
      border: 1px solid rgba(199, 199, 206, 1);
      border-radius: 4px;
      .#{$prefix}-expand-icon>.anticon {
        color: $primaryColor;
      }
      .#{$prefix}-header-text {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

.#{$prefix}-icon-position-start {
  .#{$prefix}-item-active {
    border: 1px solid rgba(199, 199, 206, 1);
    border-radius: 4px;
    .#{$prefix}-header {
      border:none;
    }
    .#{$prefix}-content-active {
      border: none;
    }
  }
}

.#{$prefix}-item {
  margin-bottom: 8px;
  border: transparent;
}
