@import 'variables';

$prefix: $antPrefix + -steps;

.#{$prefix} {
  .#{$prefix}-item-title {
    color: $textColor;
    &::after {
      background: $contrastBackground;
    }
  }

  &-item-process > .#{$prefix}-item-container {
    & > .#{$prefix}-item-content {
      .#{$prefix}-item-title {
        color: $textColor;
        &::after {
          background-color: $contrastBackground;
        }
      }
      .#{$prefix}-item-subtitle {
        color: $textColor;
      }
    }
    & > .#{$prefix}-item-tail::after {
      background-color: $contrastBackground;
    }
  }

  &-item-wait {
    .#{$prefix}-item-icon {
      background-color: $contrastBackground;
      border-color: $disabledColor;
      & > .#{$prefix}-icon {
        color: $disabledColor;
      }
    }
    & > .#{$prefix}-item-container > .#{$prefix}-item-content {
      & > .#{$prefix}-item-title {
        color: $disabledColor;
        &::after {
          background-color: $contrastBackground;
        }
      }
      .#{$prefix}-item-subtitle {
        color: $textColor;
      }
    }
    & > .#{$prefix}-item-container .#{$prefix}-item-tail::after {
      background-color: $contrastBackground;
    }
  }
  &-item-finish {
    .#{$prefix}-item-icon {
      color: $primaryColor;
    }
    & > .#{$prefix}-item-container > .#{$prefix}-item-content {
      & > .#{$prefix}-item-title,
      .#{$prefix}-item-subtitle {
        color: $primaryColor;
      } 
    }
  }
}
.#{$prefix} {
  .#{$prefix}-item-finish {
    .#{$prefix}-item-container {
      .#{$prefix}-item-tail::after {
        background-color: $primaryColor;
      }
      .#{$prefix}-item-icon {
        border-color: $primaryColor;
        background: transparent;
        .#{$prefix}-icon {
          color: $primaryColor;
          .#{$prefix}-icon-dot {
            background: $primaryColor;
          }
        }
      }
      .#{$prefix}-item-content {
        .#{$prefix}-item-title {
          font-weight: 400;
          line-height: 17px;
        }
      }
      &:hover {
        .#{$prefix}-item-icon {
          border-color: $primaryColor !important;
        }
        .#{$prefix}-item-content {
          .#{$prefix}-item-title {
            color: $primaryColor;
          }
        }
      }
    }
  }
  .#{$prefix}-item-active {
    .#{$prefix}-item-container {
      .#{$prefix}-item-tail::after {
        background-color: rgba(0, 0, 0, 0.25);
      }
      .#{$prefix}-item-icon {
        border-color: $primaryColor;
        background: $primaryColor;
        .#{$prefix}-icon {
          color: $primaryColor;
          .#{$prefix}-icon-dot {
            background: $primaryColor;
          }
        }
      }
      .#{$prefix}-item-content {
        .#{$prefix}-item-title {
          color: $primaryColor;
          font-weight: 400;
          line-height: 17px;
        }
      }
    }
  }
  .#{$prefix}-item-wait {
    .#{$prefix}-item-container {
      .#{$prefix}-item-tail::after {
        background-color: rgba(0, 0, 0, 0.25);
      }
      .#{$prefix}-item-icon {
        border-color: $textColorSecondary;
        background: transparent;
      }
      .#{$prefix}-item-content {
        .#{$prefix}-item-title {
          color: $textColorSecondary;
          font-weight: 400;
          line-height: 17px;
        }
      }

      &:hover {
        .#{$prefix}-item-content {
          .#{$prefix}-item-title {
            color: $textColor;
          }
        }
      }
    }
  }
}