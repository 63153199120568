@import 'variables';

$prefix: $antPrefix + -switch;

.#{$prefix} {
  border-color: $borderColor;
  //background-color: $contrastBackground;
  &-checked {
    background-color: $primaryColor;
  }
  margin: 0 4px;

  .#{$prefix}-inner {
    font-size: 11px;
  }
}

.active-switch-label {
  color: $primaryColor;
  font-weight: 600;
}
